<template>
  <div class="page1">
    <div class="content">
      <div class="firstBox">
        <!-- 宋晓阳-概览 -->
        <div class="title">数据总览</div>
        <div class="box_content">
          <div class="box_content_item" v-for="(item, idx) in contentItem" :key="idx">
            <img class="img" :src="item.src" alt="" />
            <div class="box_content_detail">
              <div class="first_num">
                <div class="first_num_box">
                  <span>{{ housands(item.parkNum) }}</span> <span class="units">{{ item.unit }}</span>
                </div>
              </div>
              <div class="first_title">
                <div>{{ item.title }}</div>
                <div v-if="item.title == '11'" class="kino">
                  较昨日
                  <img :src="kinoUp" alt="" srcset="" />
                </div>
              </div>
              <div class="border"></div>
              <div class="two_box">
                <div>{{ item.twoTitle }}</div>
                <div>{{ item.allParkNum }}{{ item.unitOne ? item.unitOne : item.unit }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="list">
        <el-row>
          <el-col :span="16" class="colLeft">
            <div class="divSize">
              <!-- <div class="divIcon"></div> -->
              <h2>
                停车收入概览
                <!-- <el-tooltip
                  slot="left"
                  content="停车收入概览：按支付时间统计实收，指每个区域，支付时间在最近7天，所有车辆实收数据。"
                  placement="top"
                >
                  <i class="el-icon-question" style="color: #d0d0d0"></i>
                </el-tooltip> -->
              </h2>
              <parkingRevenue></parkingRevenue>
            </div>
          </el-col>
          <el-col :span="8" class="colRight">
            <div class="divSize">
              <!-- <div class="divIcon"></div> -->
              <h2 class="top5">
                停车收入TOP5
                <!-- <el-tooltip
                  slot="left"
                  content="停车收入top5：统计每个道路最近7天支付的实收金额，取前5展示"
                  placement="top"
                >
                  <i class="el-icon-question" style="color: #d0d0d0"></i>
                </el-tooltip> -->
              </h2>
              <el-table
                :header-cell-style="cellStyle"
                :cell-style="RowCellStyle"
                :data="parkRevenueTopList"
                style="width: 100%"
              >
                <el-table-column
                  prop="prop"
                  align="center"
                  label="排名"
                  show-overflow-tooltip
                  width="60"
                >
                  <template slot-scope="scope">
                    <!-- {{(scope.row.money / 100).toFixed(2)}} -->
                    <!-- {{ '../../../assets/img/top' + scope.row.top + '.png' }} -->

                    <img :src="scope.row.src" style="text-align: center" />
                  </template>
                </el-table-column>
                <el-table-column
                  prop="parkName"
                  align="left"
                  :label="$t('searchModule.park_name')"
                  show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column prop="prop" align="left" label="收入" width="120">
                  <template slot-scope="scope">
                    {{ (scope.row.money / 100).toFixed(2) }}
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="list">
        <el-row>
          <el-col :span="16" class="colLeft">
            <div class="divSize">
              <!-- <div class="divIcon"></div> -->
              <h2>
                停车记录概览
                <!-- <el-tooltip
                  slot="left"
                  content="停车记录概览：按入场时间统计每个地区最近7天停车记录数。"
                  placement="top"
                >
                  <i class="el-icon-question" style="color: #d0d0d0"></i>
                </el-tooltip> -->
              </h2>
              <!-- <parkbarChart :parkRecord="parkRecord"></parkbarChart> -->
              <parkingRecord></parkingRecord>
            </div>
          </el-col>
          <el-col :span="8" class="colRight">
            <div class="divSize">
              <!-- <div class="divIcon"></div> -->
              <h2 class="top5">
                停车记录TOP5
                <!-- <el-tooltip
                  slot="left"
                  content="停车记录top5：按入场时间统计每个车场最近7天停车记录，取前5展示"
                  placement="top"
                >
                  <i class="el-icon-question" style="color: #d0d0d0"></i>
                </el-tooltip> -->
              </h2>
              <el-table
                :header-cell-style="cellStyle"
                :cell-style="RowCellStyle"
                :data="parkRecordList"
                style="width: 100%"
              >
                <el-table-column
                  prop="prop"
                  align="center"
                  label="排名"
                  show-overflow-tooltip
                  width="60"
                >
                  <template slot-scope="scope">
                    <!-- {{(scope.row.money / 100).toFixed(2)}} -->
                    <!-- {{ '../../../assets/img/top' + scope.row.top + '.png' }} -->

                    <img :src="scope.row.src" />
                  </template>
                </el-table-column>
                <el-table-column prop="parkname" :label="$t('searchModule.park_name')" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="parkingCnt" label="次数" width="120"> </el-table-column>
              </el-table>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
//  import flex from "./flex";
import barchart from "./barchart";
import myComponent from "@/components/autocomplete/myautoComponent";
import parkbarChart from "@/components/parkbarChart/parkbarChart";
import parkingRevenue from "./parkingRevenue";
import parkingRecord from "./parkingRecord";
export default {
  components: {
    //      flex,
    // barchart,
    // parkbarChart,
    parkingRevenue,
    parkingRecord,
  },
  data() {
    return {
      top1: require("@/assets/img/top1.png"),
      top2: require("@/assets/img/top2.png"),
      top3: require("@/assets/img/top3.png"),
      top4: require("@/assets/img/top4.png"),
      top5: require("@/assets/img/top5.png"),
      kinoUp: require("@/assets/img/kinoUp.png"),
      kinoDown: require("@/assets/img/kinoDown.png"),
      contentItem: [
        {
          src: require("@/assets/img/parking.png"),
          parkNum: 100,
          allParkNum: 200,
          title: "车场总数",
          unit: "个",
          twoTitle: "泊位总数",
        },
        {
          src: require("@/assets/img/parkOne.png"),
          parkNum: 100,
          allParkNum: 200,
          title: "今日停车记录数",
          unit: "个",
          twoTitle: "日均停车记录数",
        },
        {
          src: require("@/assets/img/parkTwo.png"),
          parkNum: 100,
          allParkNum: 200,
          title: "今日收入总额",
          unit: "元",
          twoTitle: "日均收入总额",
        },
        {
          src: require("@/assets/img/parkThree.png"),
          parkNum: 100,
          allParkNum: 200,
          title: "日均时长泊位利用率",
          twoTitle: "日均泊位周转次数",
          unit: "%",
          unitOne: "个",
        },
      ],
      type3: "c3",
      parkNum: {},
      exitCount: "", // 今日停车记录数
      actualPay: "", // 今日实收总额
      parkCount: "",
      berthCount: "",
      parkTimeRatio: "", // 日均泊位时长利用率
      averageExitCount: "", // 日均停车记录数
      averageActualPay: "", // 日均实收总额
      averageBerthTurnover: "", // 日均泊位周转次数
      parkMoneyList: [], // 停车实缴率Top5
      parkRecordList: [], // 停车记录TOP5
      parkRevenueTopList: [], // 停车收入TOP5
      parkRecord: [],
      durationAnalysis: [
        {
          areaTypeName: "唐山市",
          money: 3,
          money1: 20,
        },
        {
          areaTypeName: "保定市",
          money: 30,
          money1: 10,
        },
        {
          areaTypeName: "廊坊市",
          money: 10,
          money1: 25,
        },
        {
          areaTypeName: "张家口",
          money: 10,
          money1: 25,
        },
      ],
      //        units: [
      //          {
      //            n: "133",
      //            name: "车场总数",
      //            name1: "泊位总数",
      //            outStyle: {
      //              'background': 'linear-gradient(270deg,#ffc774, #ff854a)',
      //              'box-shadow': '2px 5px 10px 0px rgba(255,183,125,0.51)'
      //            },
      //            img: require('../../assets/img/park.png'),
      //            unit: "个",
      //            content: '车场总数：商用状态下的停车场总数  泊位总数：商用状态下的停车场泊位总数',
      //          }
      //        ],
    };
  },
  methods: {
    cellStyle({ columnIndex }) {
      if (columnIndex == 0) {
        return {
          textAlign: "center",
        };
      }
    },
    RowCellStyle({ columnIndex }) {
      if (columnIndex == 1) {
        return {
          textAlign: "left",
          height: "64px",
        };
      }
      if (columnIndex == 2) {
        return {
          textAlign: "right",
          padding: "0 16px",
          height: "64px",
        };
      }
      if (columnIndex == 0) {
        return {
          textAlign: "center",
          height: "64px",
        };
      }
      return {
        textAlign: "center",
        height: "64px",
      };
    },

    housands(num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    // 车场总数，泊位总数
    getParkBuildInfo() {
      // let url = '/acb/2.0/parkReport/parkBuildInfo';
      let url = "/acb/2.0/dataAnalysisOverView/getParkAndBerthCount";
      this.$axios
        .get(url, {
          data: {
            dataSource: 1,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.contentItem[0].parkNum = res.value.parkCount;
            // this.contentItem[0].parkNum = 102321312;
            this.contentItem[0].allParkNum = res.value.berthCount;
            // this.parkCount = res.value.parkCount;
            // this.berthCount = res.value.berthCount;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    getTodayParkingInfo() {
      let url = "/acb/2.0/parkReport/todayParkingInfo";
      this.$axios
        .get(url, {
          data: {
            dataSource: 1,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            // this.exitCount = res.value.exitCount;
            this.actualPay = res.value.actualPay;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    // 停车记录数替换
    getParkingInfo() {
      // let url = "/acb/2.0/inRoadMain/parkRecordStatisticsInfo";
      let url = "/acb/2.0/parkRecordOverview/parkRecordStatisticsInfo";
      this.$axios
        .get(url, {
          data: {
            dataSource: 1,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            // this.exitCount = res.value.todayParkRecordCount;
            // this.averageExitCount = res.value.beforeParkRecordCount;
            this.contentItem[1].parkNum = res.value.todayParkRecordCount;
            this.contentItem[1].allParkNum = res.value.beforeParkRecordCount;
            this.contentItem[3].parkNum = res.value.averageDailyBerthUtilizationRate;
            this.contentItem[3].allParkNum = res.value.averageDailyBerthTurnover;
            this.parkTimeRatio = res.value.averageDailyBerthUtilizationRate;
            this.averageBerthTurnover = res.value.averageDailyBerthTurnover;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    //  泊位周转率
    getBerlvu() {
      let url = "/acb/2.0/inRoadMain/berthStatisticsInfo";
      this.$axios
        .get(url, {
          data: {
            dataSource: 1,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.parkTimeRatio = res.value.averageDailyBerthUtilizationRate;
            this.averageBerthTurnover = res.value.averageDailyBerthTurnover;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    // 各区域停车情况
    getQueryRecentDaysAmountByArea() {
      // let url = "/acb/2.0/parkReport/queryRecentDaysAmountByArea"; 老接口
      let url = "/acb/2.0/aceParkAnalysis/aceTodayIncome";
      this.$axios
        .get(url, {
          data: {
            areaId: "",
            dataSource: 1,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            // this.parkTimeRatio = res.value.amount.parkTimeRatio
            // this.averageExitCount = res.value.amount.averageExitCount
            this.averageActualPay = res?.value?.avg;
            this.actualPay = res?.value?.today;
            this.contentItem[2].parkNum = res?.value?.today;
            this.contentItem[2].allParkNum = res?.value?.avg;
            // this.averageBerthTurnover = res.value.amount.averageBerthTurnover
            // this.parkRecord = res.value.list;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    // 停车实缴率TOP5
    getParkMoney() {
      let url = "/acb/2.0/parkReport/queryRecentDaysExitPayRatioTopN";
      this.$axios
        .get(url, {
          data: {
            dataSource: 1,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.parkMoneyList = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    // 停车收入TOP5
    handleGetParkRevenueTop() {
      // let url = "/acb/2.0/inRoadMain/parkIncomeTop"; 老接口
      let url = "/acb/2.0/aceParkAnalysis/aceIncomeRank";
      this.$axios
        .get(url, {
          data: {
            areaId: "",
            dataSource: 1,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            let data = res.value ?? [];
            this.parkRevenueTopList = data.splice(0, 5);
            this.parkRevenueTopList.forEach((item) => {
              if (item.top == 1) {
                item.src = this.top1;
              } else if (item.top == 2) {
                item.src = this.top2;
              } else if (item.top == 3) {
                item.src = this.top3;
              } else if (item.top == 4) {
                item.src = this.top4;
              } else if (item.top == 5) {
                item.src = this.top5;
              }
            });
            console.log(this.parkRevenueTopList, "parkRevenueTopList");
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    // 停车记录TOP5
    getParkRecord() {
      // let url = '/acb/2.0/parkReport/queryRecentDaysExitCountTopN';
      // let url = "/acb/2.0/inRoadMain/parkRecordTop"; 老接口
      let url = "/acb/2.0/parkRecordOverview/parkRecordRank";
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          let data = res.value ?? [];
          this.parkRecordList = data.splice(0, 5);
          this.parkRecordList[0].src = this.top1;
          this.parkRecordList[1].src = this.top2;
          this.parkRecordList[2].src = this.top3;
          this.parkRecordList[3].src = this.top4;
          this.parkRecordList[4].src = this.top5;
          // console.log(this.parkRecordList, "parkRecordList");
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getParkBuildInfo();
      // this.getTodayParkingInfo();
      this.getQueryRecentDaysAmountByArea();
      // this.getParkMoney()
      this.handleGetParkRevenueTop(); // 停车收入top
      this.getParkRecord();
      this.getParkingInfo();
      // this.getBerlvu();
    });
  },
  created() {},
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">

.firstBox {
  padding: 20px 20px 25px
  margin-bottom: 16px
  background-color: #fff
  border-radius: 4px 4px 4px 4px
  .title {
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: #1D2129;
    line-height: 24px;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }
  .box_content {
    display: flex;
    justify-content: space-between;
    .box_content_item {
      position: relative;
      padding: 36px 0px 0px 0px
      width 270px
      &:last-child {
        padding-right: 0
      }
      .img {
        position: absolute;
        top: 10px
        left: -10px
        // width 66px
        // height 70px
      }
      .box_content_detail {
        padding: 25px 25px 0 24px
        background: url('../../../assets/img/backGroundUnion.png') no-repeat
        background-size: 100% 100%
        .first_num {
          position: relative;
          margin-bottom: 18px
          text-align: right
          .first_num_box {
            height: 22px
            span {
              margin-right: 20px
              height: 22px !important
              line-height: 22px !important
              font-weight: 500;
              font-size: 28px;
              color: #000000;
              text-align: right;
              font-style: normal;
              text-transform: none;
            }
            .units {
              position: absolute;
              bottom: 0;
              right: -20px;
              font-weight: 500;
              font-size: 16px !important;
              color: #1E1E1E;
              text-align: right;
              font-style: normal;
              text-transform: none;
            }
          }
        }
        .first_title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 14px
          height: 22px
          line-height: 22px
          font-weight: 500;
          font-size: 16px;
          color: #1E1E1E;
          font-style: normal;
          text-transform: none;
          .kino {
            font-weight: 400;
            font-size: 12px;
            color: #4E5969;
            line-height: 20px;
            text-align: left;
            font-style: normal;
            text-transform: none;
          }
        }
        .border {
          border: 0.3px solid #A3B2C7;
        }
        .two_box {
          display: flex
          justify-content: space-between
          padding: 14px 0
          height: 24px
          line-height: 24px
          font-weight: 400;
          font-size: 14px;
          color: #788393;
          text-align: center;
          font-style: normal;
          text-transform: none;
        }
      }
    }
  }
}
.flexAround {
  display: flex;
  height: 100%;
  justify-content: space-around;
  align-items: center;
}

// .units {
//   margin: 10px;
// }

.content {
  // background: #F0F6F9;
  // padding: 20px 0px;
}

.list {
  padding-bottom: 16px;

  &:last-child {
    padding-bottom: 0px;
  }
  .el-col {
    box-sizing: border-box;
  }

  .colLeft {
    padding-right: 10px;
    min-width: 560px;
  }

  .colRight {
    padding-left: 10px;
  }

  .divSize {
    border-radius: 4px;
    padding: 20px;
    height: 400px
    background-color: #fff;
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.1);
  }

  h2 {
    font-weight: 500;
    font-size: 16px;
    color: #1D2129;
    line-height: 24px;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }

  .top5 {
    margin-bottom 16px
    font-weight: 500;
    font-size: 16px;
    color: #1D2129;
    line-height: 24px;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }
}

.row {
  width: 100%;
  color: #333333;
  margin-left: 15px;

  .col {
    width: 100%;
    height: 50px;
    line-height: 50px;

    .indexNumber {
      display: inline-block;
      width: 20px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      margin-right: 10px;
      font-size: 14px;
      border-radius: 50%;
    }

    .topNumber {
      background-color: #F8921C;
      color: white;
    }

    .bottomNumber {
      color: rgba(0, 0, 0, 0.65);
      background-color: #FFE7AD;
    }

    .item {
      position: absolute;
      left: 40px;
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .itemMoney {
    float: right;
    font-size: 15px;
    margin-right: 20px;
  }
}

.divIcon {
  display: inline-block;
  position: absolute;
  width: 4px;
  height: 15px;
  background: #0c66ff;
}

.overviewBox {
  display: inline-block;
  width: 22.5%;
  height: 106px;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
  color: #fff;

  .leftShow {
    float: left;
    display: inline-block;
    margin: 30px 20px 0px 20px;
  }

  .rightShow {
    margin-left: 20px;
    margin-top: 14px;
    font-size: 14px;
  }

  img {
    width: 46px;
    height: 46px;
  }

  .top {
    margin-bottom: 8px;
  }

  .center {
    font-size: 22px;
    margin-bottom: 14px;

    .unit {
      font-size: 14px;
    }
  }
}

.num {
  margin-left: 5px;
}

.background1 {
  background: linear-gradient(270deg, #ffc774, #ff854a);
  box-shadow: 2px 5px 10px 0px rgba(255, 183, 125, 0.51);
}

.background2 {
  background: linear-gradient(270deg, #00b4ff, #3d65ff);
  box-shadow: 2px 5px 10px 0px rgba(127, 166, 255, 0.51);
}

.background3 {
  background: linear-gradient(270deg, #00e7ef, #00bfc0);
  box-shadow: 2px 5px 10px 0px rgba(76, 223, 255, 0.51);
}

.background4 {
  background: linear-gradient(270deg, #00e888, #15d770);
  box-shadow: 2px 5px 10px 0px rgba(76, 255, 120, 0.51);
}
</style>
